import React from 'react';
import { graphql } from 'gatsby';
import { startCase } from 'lodash';
import { FaArrowLeft, FaArrowRight, FaBookOpen, FaGlobe } from 'react-icons/all';
import styled from 'styled-components';
import AutoLink from '../components/common/AutoLink';
import PostDate from '../components/common/PostDate';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { IconButton } from '../components/common/Button';
import { clearfix, linkColor, spaceChildren } from '../styles/mixins';
import { BlogListContext } from '../types/types';

type PropsType = {
  data: GatsbyTypes.blogListQuery;
  pageContext: BlogListContext;
}

export default class BlogList extends React.Component<PropsType, {}> {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const { currentPage, numPages, category } = this.props.pageContext;

    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;

    const slug = `/${category}`;
    const prevPage = isFirst ? undefined : `${slug}/${currentPage - 1 === 1 
      ? '' : (currentPage - 1).toString()}`;
    const nextPage = isLast ? undefined : `${slug}/${(currentPage + 1).toString()}`;

    const pageTitle = startCase(category);

    return (
      <Layout>
        <SEO
          title={pageTitle}
        />
        <div className="blog-list">
          <h1>{pageTitle}</h1>
          {posts.map(({ node }) => {
            const { id } = node;
            const { title, slug, date, passthroughUrl } = node.frontmatter;
            const articleUrl = `/${category}/${slug}`;

            // Clean up excerpt so it always ends in ellipsis
            let { excerpt } = node;
            const ellipsis = '…';
            if (/[^A-Za-z\d]$/.test(excerpt)) {
              excerpt = excerpt.substring(0, excerpt.length - 1);
            }
            if (!excerpt.endsWith(ellipsis)) {
              excerpt = `${excerpt}${ellipsis}`;
            }

            return (
              <Article key={id}>
                <ArticleTitle>
                  <TitleLink to={articleUrl}>{title}</TitleLink>
                </ArticleTitle>
                <ArticleMeta>
                  <PostDate date={date} />
                </ArticleMeta>

                <div>
                  <p>{excerpt}</p>
                </div>

                <ArticleButtonStrip>
                  <IconButtonStyled href={articleUrl}>
                    <span>Read more</span>
                    <FaBookOpen />
                  </IconButtonStyled>
                  {!!passthroughUrl && (
                    <IconButtonStyled href={passthroughUrl} newTab>
                      <span>View linked content</span>
                      <FaGlobe />
                    </IconButtonStyled>
                  )}
                </ArticleButtonStrip>
              </Article>
            );
          })}

          <ButtonStrip>
            <PrevButton primary href={prevPage} rel="prev" hidden={isFirst}>
              <FaArrowLeft />
              <span>Prev Page</span>
            </PrevButton>
            {numPages > 1 && (
              <PageCount>Page {currentPage} of {numPages}</PageCount>
            )}
            <NextButton primary href={nextPage} rel="next" hidden={isLast}>
              <span>Next Page</span>
              <FaArrowRight />
            </NextButton>
          </ButtonStrip>
        </div>
      </Layout>
    );
  }
}

const Article = styled.article`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
`;
const ArticleTitle = styled.h2`
  display: inline-block;
  position: relative;
  line-height: 2.25rem;
  font-size: 1.5rem;
  
  a {
    ${linkColor('inherit')};
    text-decoration: none;
  }
`;
const TitleLink = styled(AutoLink)`
  display: block;
`;
const ArticleMeta = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;
const ArticleButtonStrip = styled.div`
  display: flex;
  ${spaceChildren('8px')};
`;
const ButtonStrip = styled.nav`
  display: flex;
  align-items: center;
  margin-top: 24px;
  ${clearfix()};
`;
const IconButtonStyled = styled(IconButton)`
  flex-shrink: 0;
`;
const PageCount = styled.span`
  color: #333;
  flex-grow: 1;
  text-align: center;
`;
const NavButton = styled(IconButtonStyled)<{ hidden: boolean }>`
  visibility: ${p => p.hidden && 'hidden'};
`;
const PrevButton = styled(NavButton)`
`;
const NextButton = styled(NavButton)`
`;


type PostNode = {
  id: string;
  frontmatter: {
    title: string;
    date: string;
    slug: string;
  };
  excerpt: string;
};

export const blogListQuery = graphql`
  query blogList($skip: Int!, $limit: Int!, $category: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { category: {eq: $category}}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            passthroughUrl
          }
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`;
